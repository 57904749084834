import React from 'react';

const ErrorMessage = ({ message }) => {
  return (
    <div className="error-message">
      <i className="error-message__icon icon icon--circle-close red"></i>
      <p>{message}</p>
    </div>
  );
};

export default ErrorMessage;
