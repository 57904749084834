export const amplitude_tracking = (eventName, rating, chat_thread_id) => {
  let amplitudeSofabuddyProperties = {};
  const user = window.Sofatutor?.user || {};
  const profileProperties = {
    profile_id: user.profile_id,
    profile_type: user.profile_type,
    profile_level: user.level
  };

  switch (eventName) {
    case 'sofabuddy_csat_given':
      amplitudeSofabuddyProperties = {
        ...profileProperties,
        csat_valuation: rating
      };
      break;
    case 'sofabuddy_onboarding_seen':
      amplitudeSofabuddyProperties = {
        ...profileProperties
      };
      break;
    case 'sofabuddy_clicked':
      amplitudeSofabuddyProperties = {
        ...profileProperties
      };
      break;
    case 'sofabuddy_chat_started':
      amplitudeSofabuddyProperties = {
        ...profileProperties,
        chat_session_id: chat_thread_id
      };
      break;
    case 'sofabuddy_chat_ended':
      amplitudeSofabuddyProperties = {
        ...profileProperties
      };
      break;
    case 'sofabuddy_past_history_clicked':
      amplitudeSofabuddyProperties = {
        ...profileProperties
      };
      break;
    case 'sofabuddy_daily_message_limit_reached':
      amplitudeSofabuddyProperties = {
        ...profileProperties
      };
      break;
  }
  if (
    window.Sofatutor &&
    window.Sofatutor.tracking &&
    window.Sofatutor.tracking.amplitude
  ) {
    window.Sofatutor.tracking.amplitude.track(
      eventName,
      amplitudeSofabuddyProperties
    );
  }
};
