import { Controller } from 'stimulus';
import LocalProfileStorage from '../../lib/LocalProfileStorage';

export default class extends Controller {
  static targets = ['gridButton', 'listButton'];

  connect() {
    const currentViewMode =
      LocalProfileStorage.getItem('practice_topics_view_mode') || 'grid';

    this.toggleViewMode(null, currentViewMode);
  }

  goBack = () => {
    const referrerIsSameOrigin =
      document.referrer.indexOf(location.origin) === 0;
    const useCaseSelectorUrl = window.location.pathname.replace(/\/[^/]*$/, '');

    if (referrerIsSameOrigin) window.history.back();
    else Turbolinks.visit(useCaseSelectorUrl);
  };

  toggleViewMode(el, mode) {
    let targetButton;
    let isList;
    if (el) {
      targetButton = el.target.closest('button');
      isList = targetButton === this.listButtonTarget;
    } else isList = mode === 'list';

    this.listButtonTarget.classList.toggle('is-active', isList);
    this.gridButtonTarget.classList.toggle('is-active', !isList);

    const viewMode = isList ? 'list' : 'grid';
    this.dispatch('viewMode', { detail: { viewMode } });

    LocalProfileStorage.setItem('practice_topics_view_mode', viewMode);
  }
}
