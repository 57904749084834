import { getCookie, setCookie } from '../../helpers/cookie_helper';

export const triggerSurvey = () => {
  const surveyCookieName = '_sofabuddy_post_first_usage_survey';
  const surveyEventName = 'sofabuddy_post_first_usage_survey';
  const surveyTimeoutMs = 10 * 60 * 1000;

  const hasSurveyCookie = Boolean(getCookie(surveyCookieName));

  const triggerHotjarEvent = () => {
    window.hj?.('trigger', surveyEventName);
    if (window.Sofatutor.consentGivenForCategory('analytics')) {
      hj('identify', Sofatutor.user?.id);
    }
  };

  if (!hasSurveyCookie) {
    setCookie(surveyCookieName, 'true');
    setTimeout(() => triggerHotjarEvent(), surveyTimeoutMs);
  } else {
    triggerHotjarEvent();
  }
};
