import React from 'react';

const WarningMessage = ({ message }) => {
  return (
    <div className="warning-message">
      <i className="warning-message__icon icon"></i>
      <p>{message}</p>
    </div>
  );
};

export default WarningMessage;
