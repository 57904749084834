import React from 'react';
import { css } from '@emotion/core';

const variantsMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  p: 'p'
};

const style = styles => css`
   {
    color: #333;
    ${styles};
  }
`;

interface TypographyProps {
  /**
   * Typography variant
   */
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
  /**
   * Class name
   */
  className?: string;
  /**
   * Extra styles
   */
  extraStyles?: {};
  /**
   * Is the text bold?
   */
  bold?: boolean;
  /**
   * Font family
   */
  fontFamily?: string;
  /**
   * Typography contents
   */
  children?: React.ReactNode;
}

const Typography = ({
  variant,
  className,
  extraStyles,
  bold,
  children,
  fontFamily = 'Open Sans',
  ...props
}: TypographyProps) => {
  const Component = variant ? variantsMapping[variant] : 'p';
  const customStyles = {
    fontFamily: `'${fontFamily}' !important`,
    fontWeight: bold ? 700 : 400,
    ...extraStyles
  };
  return (
    <Component css={style(customStyles)} className={className} {...props}>
      {children}
    </Component>
  );
};

export const GrundschriftTypography = props => (
  <Typography fontFamily="Grundschrift" {...props}>
    {props.children}
  </Typography>
);

export default Typography;
