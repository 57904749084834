/*
 * General utils for managing cookies.
 */
export function setCookie(name, value, options = {}) {
  if (name && value) {
    const cookieOptions = Object.entries(options)
      .map(item => item.join('='))
      .join('; ');

    document.cookie = `${name}=${value}; ${cookieOptions}`.trim();
  }
}

export function getCookie(name) {
  if (!name) return '';

  const cookies = document.cookie.split('; ').map(cookie => cookie.split('='));
  const cookie = cookies.find(([key]) => key === name);

  return cookie ? cookie[1] : '';
}

export function deleteCookie(name) {
  if (name) {
    const date = new Date();

    // Set it expire in -1 days
    date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);

    // Set it
    document.cookie = `${name}=; expires=${date.toUTCString()}; path=/`;
  }
}
