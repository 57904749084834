import { Controller } from 'stimulus';
import LocalProfileStorage from '../../lib/LocalProfileStorage';
import makeRequest from '../../lib/fetchService';

export default class extends Controller {
  static targets = ['topicsContainer', 'topicsGrid', 'topicsList'];

  connect() {
    this.viewMode =
      LocalProfileStorage.getItem('practice_topics_view_mode') || 'grid';

    const activeItems =
      LocalProfileStorage.getItem('active_topic_group_accordion') || [];

    if (!activeItems.length) return;

    this.setActiveAccordionCards(activeItems);
    this.handleContainersState(this.viewMode);
  }

  async toggleActive(e) {
    const cardElement = e.currentTarget;
    const isActive = cardElement.classList.toggle('is-active');

    const topicsContainer = cardElement.nextElementSibling;
    const topicGroupId = topicsContainer.dataset.topicGroupId;
    const contentLevel = topicsContainer.dataset.contentLevel;

    // fetch topics just if they haven't been fetched yet
    if (topicsContainer.innerHTML === '' && isActive) {
      const data = await this.fetchTopics(topicsContainer, isActive);
      topicsContainer.innerHTML = data;
    }

    const storageKey = `${topicGroupId}_${contentLevel}`;
    this.setContainerStyles(topicsContainer, isActive);
    this.handleContainersState(this.viewMode);
    this.updateLocalStorage(storageKey, isActive);
  }

  onViewModeChange(e) {
    this.viewMode = e.detail.viewMode;
    this.handleContainersState(this.viewMode);
  }

  handleContainersState(viewMode) {
    const isGrid = viewMode === 'grid';

    this.topicsGridTargets.forEach(el =>
      el.classList.toggle('is-active', isGrid)
    );

    this.topicsListTargets.forEach(el =>
      el.classList.toggle('is-active', !isGrid)
    );
  }

  setActiveAccordionCards(activeItems) {
    activeItems.forEach(async id => {
      const activeAccordion = Array.from(this.topicsContainerTargets).find(
        el => `${el.dataset.topicGroupId}_${el.dataset.contentLevel}` === id
      );

      if (activeAccordion) {
        activeAccordion.previousElementSibling.classList.add('is-active');
        const data = await this.fetchTopics(activeAccordion);
        activeAccordion.innerHTML = data;
        this.setContainerStyles(activeAccordion, true);
        this.handleContainersState(this.viewMode);
      }
    });
  }

  async fetchTopics(topicsContainer) {
    const url = `${topicsContainer.dataset.fetchUrl}?practice_topic_group_ids=${topicsContainer.dataset.topicGroupId}&with_and_without_AT=true&format=js`;
    const response = await makeRequest(url);
    const data = await response.text();

    return data;
  }

  updateLocalStorage(storageId, isActive) {
    let activeItems =
      LocalProfileStorage.getItem('active_topic_group_accordion') || [];

    if (isActive) {
      activeItems.push(storageId);
      LocalProfileStorage.setItem('active_topic_group_accordion', activeItems);
    } else {
      activeItems = activeItems.filter(id => id !== storageId);
      if (activeItems.length)
        LocalProfileStorage.setItem(
          'active_topic_group_accordion',
          activeItems
        );
      else LocalProfileStorage.removeItem('active_topic_group_accordion');
    }
  }

  // Set container height for animation (maxHeight is not fixed to set it in CSS)
  setContainerStyles(container, isActive) {
    // wait for the DOM to update
    requestAnimationFrame(() => {
      container.style.maxHeight =
        (isActive ? container.scrollHeight * 4 : 0) + 'px'; // 4 times to cover the case when resizing the window to small device
      container.classList.toggle('is-active', isActive);
    });
  }
}
