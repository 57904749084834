import React from 'react';
import '../translations/componentsInit';
import { useTranslation, Trans } from 'react-i18next';
import makeRequest from '@lib/fetchService';

const WelcomeMessage = ({
  showSofabuddySurvey,
  currentProfileId,
  sofabuddyCookieExists,
  sofabuddySurveyClicked
}) => {
  const { t } = useTranslation();
  const surveyUrl = `https://de.surveymonkey.com/r/GTML9KP?user_id=${currentProfileId}`;

  const handleSurveyClick = e => {
    e.preventDefault();
    const url = '/sofabuddy_survey_clicked';
    makeRequest(url, 'PATCH').then(response => {
      if (response.ok) {
        window.open(surveyUrl, '_blank');
      }
    });
  };

  const renderSurveyMessage = () => {
    return (
      <Trans
        i18nKey="sofabuddy_chat_bot.survey_welcome_message"
        components={
          <a
            key={0}
            href={surveyUrl}
            onClick={handleSurveyClick}
            aria-label={t(
              'sofabuddy_chat_bot.survey_welcome_message_arialabel'
            )}
          />
        }
      />
    );
  };

  const renderWelcomeMessage = () => {
    if (showSofabuddySurvey && !sofabuddySurveyClicked) {
      return renderSurveyMessage();
    }
    return sofabuddyCookieExists
      ? t('sofabuddy_chat_bot.return_welcome_message')
      : t('sofabuddy_chat_bot.first_welcome_message');
  };

  return (
    <div className="rcb-bot-message rcb-bot-message-entry welcome-message">
      {renderWelcomeMessage()}
    </div>
  );
};

export default WelcomeMessage;
