import React from 'react';
import { css } from '@emotion/core';

import './ShineEffect.sass';
import shiny from './shiny.svg';

interface ShineEffectProps {
  /**
   * @instructions Parent component must have "position: relative"
   */

  /**
   * Animation Delay
   * @default 0
   * @description Delay in milliseconds
   */
  animationDelay?: number;
}

const ShineEffect = ({ animationDelay = 0 }: ShineEffectProps) => {
  const shineStyles = css`
    animation-delay: ${animationDelay}ms;
  `;

  return (
    <div className="shine-container">
      <img
        src={shiny}
        className="shine-container__effect"
        css={shineStyles}
        data-testid="shiny"
      />
    </div>
  );
};

export default ShineEffect;
