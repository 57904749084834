/**
 * Helper for storing stuff in localStorage which is profile dependent
 * format: key_profileId
 */
export default class LocalProfileStorage {
  static #getKey = key => {
    return `${key}_${window.Sofatutor?.user?.profile_id ||
      window.Sofatutor?.profile?.currentProfileId()}`;
  };

  static getItem = key => {
    const item = localStorage.getItem(this.#getKey(key));
    if (item) return JSON.parse(item);
    else return null;
  };

  static hasItem = key => {
    const item = localStorage.getItem(this.#getKey(key));
    if (item) return true;
    else return false;
  };

  static setItem = (key, data) => {
    localStorage.setItem(this.#getKey(key), JSON.stringify(data));
  };

  static removeItem = key => {
    localStorage.removeItem(this.#getKey(key));
  };
}
