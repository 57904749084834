import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['scrollToSection'];

  scrollToTarget(event) {
    event.preventDefault();
    const targetElement = this.scrollToSectionTarget;

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      console.error('Target element not found.');
    }
  }
}
