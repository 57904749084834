import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { css } from '@emotion/core';
import colors from '../../../application/_colors.scss';
import './SpeechBubble.sass';

const { blueL4, blueD4 } = colors;

export enum DynamicTrianglePosition {
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right',
  BOTTOM_CENTER = 'bottom-center',
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
  TOP_CENTER = 'top-center'
}

interface SpeechBubbleProps {
  /**
   * Background color
   * @default blue
   */
  backgroundColor?: string;
  /**
   * Text color
   * @default blue
   */
  textColor?: string;
  /**
   * Size
   * @options small, large
   */
  size?: 'small' | 'large';
  /**
   * Class name
   */
  className?: string;
  /**
   * With triangle
   * @default true
   */
  withTriangle?: boolean;
  /**
   * With shadow
   * @default true
   */
  withShadow?: boolean;
  /**
   * Dynamic triangle position
   * @options bottom-left, bottom-right, bottom-center, top-left, top-right, top-center
   */
  dynamicTrianglePosition?: DynamicTrianglePosition;
  /**
   * With slide animation
   * @default false
   */
  withSlideAnimation?: boolean;
  /**
   * Children
   */
  children: React.ReactNode;
}

const SpeechBubble = (
  {
    backgroundColor = blueL4,
    textColor = blueD4,
    size = 'large',
    className = '',
    withTriangle = true,
    withShadow = true,
    withSlideAnimation = false,
    dynamicTrianglePosition,
    children
  }: SpeechBubbleProps,
  ref
) => {
  const speechBubbleClassList = classNames(
    className,
    'speech-bubble flex-container align-center align-middle',
    {
      [`speech-bubble--${size}`]: size
    }
  );

  const triangleStyle = css`
    border-right: 20px solid ${backgroundColor}};
  `;

  const speechBubbleStyle = css`
    margin-left: ${withTriangle ? '16px' : '0'};
    background-color: ${backgroundColor};

    p {
      color: ${textColor};
    }
  `;

  const triangleClassList = classNames('triangle', {
    'triangle__dynamic-triangle': dynamicTrianglePosition,
    'triangle__dynamic-triangle--bottom-left':
      dynamicTrianglePosition === 'bottom-left',
    'triangle__dynamic-triangle--bottom-right':
      dynamicTrianglePosition === 'bottom-right',
    'triangle__dynamic-triangle--bottom-center':
      dynamicTrianglePosition === 'bottom-center',
    'triangle__dynamic-triangle--top-left':
      dynamicTrianglePosition === 'top-left',
    'triangle__dynamic-triangle--top-right':
      dynamicTrianglePosition === 'top-right',
    'triangle__dynamic-triangle--top-center':
      dynamicTrianglePosition === 'top-center',
    'triangle__dynamic-triangle--with-shadow': withShadow
  });

  return (
    <div className="wrapper">
      {withTriangle && (
        <div className={triangleClassList} css={triangleStyle} />
      )}
      <div className={speechBubbleClassList} css={speechBubbleStyle}>
        {children}
        {withSlideAnimation && (
          <div
            data-testid="wrapper__animated-cover"
            ref={ref}
            className="wrapper__cover"
          />
        )}
      </div>
    </div>
  );
};

export default forwardRef(SpeechBubble);
