import React from 'react';
import PropTypes from 'prop-types';

import { css } from '@emotion/core';
import classnames from 'classnames';

import Typography from '../../atoms/Typography/Typography';
import ShineEffect from '../../atoms/ShineEffect/ShineEffect';
import Glitter from '../../atoms/Glitter/Glitter';

import colors from '../../../application/_colors.scss';
import './ProgressBar.sass';

const { yellow, blue, mediumGray } = colors;
interface ProgressBarProps {
  /**
   * Total
   * @default 100
   * @description Total value of the filled progress bar
   */
  total?: number;
  /**
   * Progress
   */
  progress: number;
  /**
   * widthTransitionDuration
   * @default 1
   * @description Add the duration of the width transition
   */
  widthTransitionDuration?: number;
  /**
   * WithProgressAnimation
   * @default false
   * @description Animate progress from 0 to progress value
   */
  withProgressAnimation?: boolean;
  /**
   * WithGlitterAnimation
   * @default false
   * @description Add glitter and shine when progress is 100%
   */
  withGlitterAnimation?: boolean;
  /**
   * GlitterAmount
   * @default 4
   * @description Amount of glitter
   * @max 24
   */
  glitterAmount?: number;
  /**
   * Display progress
   * @default false
   */
  displayProgress?: boolean;
  /**
   * Background color
   * @default #e6e8e5
   * @description Background color of the progress bar
   */
  progressColor?: string;
  /**
   * Progress color
   * @default #0088b5
   * @description Color of the progress bar
   */
  backgroundColor?: string;
  /**
   * Classnames
   */
  className?: string;
}

const ProgressBar = ({
  total = 100,
  progress,
  displayProgress = false,
  className = '',
  widthTransitionDuration = 1,
  withProgressAnimation = false,
  withGlitterAnimation = false,
  glitterAmount = 4,
  progressColor = blue,
  backgroundColor = mediumGray
}: ProgressBarProps) => {
  let currentProgress = progress < 0 ? 0 : progress;
  currentProgress = progress > total ? total : currentProgress;

  const totalValue = total < 0 ? 0 : total;
  const percentage = (currentProgress / totalValue) * 100;
  const showGlitter = withGlitterAnimation && percentage === 100;
  const glitterColor = yellow;

  const progressStyles = css`
    .progress {
      flex: 1 0 auto;
      margin-bottom: 0;
      background-color: ${backgroundColor};
      overflow: hidden;

      &--contrast .progress-meter {
        background-color: ${backgroundColor};
      }
    }

    .progress-meter {
      transition: width ${widthTransitionDuration}s ease-out;
      width: ${percentage}%;
    }

    .progress-meter--content {
      height: 100%;
      border-radius: 14px;
    }

    .progress-meter--content.progress-with-color {
      background-color: ${progressColor};
    }
    .progress-meter--content.progress-with-glitter {
      background-color: ${glitterColor};
    }
  `;

  const progressMeterClasses = classnames('progress-meter--content', {
    'in-progress': currentProgress !== totalValue,
    'progress-animated': withProgressAnimation,
    'progress-with-glitter': showGlitter,
    'progress-with-color': !showGlitter
  });

  return (
    <div
      className={`progress-bar grid-x align-center align-middle ${className}`}
      css={progressStyles}
      data-testid="progress-bar"
    >
      {showGlitter && (
        <>
          <Glitter
            amount={glitterAmount}
            maxSize={14}
            className="animate__animated animate__fadeIn animate__delay-1s"
          />
          <ShineEffect animationDelay={1500} />
        </>
      )}

      {displayProgress && (
        <div className="cell shrink">
          <Typography variant="h3">
            {currentProgress} / {totalValue}
          </Typography>
        </div>
      )}
      <div className="cell auto">
        <div className="progress progress--contrast" css={progressStyles}>
          <div className="progress-meter">
            <div
              className={progressMeterClasses}
              data-testid="progress-meter-content"
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  displayProgress: PropTypes.bool,
  className: PropTypes.string
};

export default ProgressBar;
