import React from 'react';
import classNames from 'classnames';
import glitter from './glitter.svg';
import { css } from '@emotion/core';

interface GlitterProps {
  /**
   * Amount of glitter
   * @default 1
   */
  amount?: number;
  /**
   * Max Size
   * @default 16
   * @description Max size of glitter in pixels
   */
  maxSize?: number;
  /**
   * Classnames
   */
  className?: string;
}

const Glitter = ({
  amount = 1,
  maxSize = 16,
  className = ''
}: GlitterProps) => {
  const classList = classNames(
    'animate__animated animate__heartBeat animate__fast animate__infinite'
  );

  const renderGlitter = () => {
    const glitterArray = [];

    for (let i = 0; i < amount; i++) {
      // Random integer between 8 and maxSize
      const size = Math.floor(Math.random() * (maxSize / 2) + 1) + 8;

      // Random float between 0 and 1
      const animationDelay = Math.random();

      // Random float between 1 and 2
      const animationDuration = Math.random() + 1;

      const glitterStyles = css`
        --animate-duration: ${animationDuration}s;
        animation-delay: ${animationDelay}s;
      `;

      glitterArray.push(
        <div className={`glitter ${className}`} key={i}>
          <img
            src={glitter}
            className={classList}
            width={size}
            height={size}
            css={glitterStyles}
            alt=""
            data-testid="glitter"
          />
        </div>
      );
    }

    return glitterArray;
  };

  return <>{renderGlitter()}</>;
};

export default Glitter;
